<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar_brand">
      <a href="/" aria-current="page" class="brand_link">
        <span class="company_name">kizax stock</span>
      </a>
      <a role="button" class="navbar_burger" aria-label="menu" aria-expanded="false" @click="openNavbarMenu()"
        :class="[is_navbar_menu_opened ? ['navbar_burger', 'is_active'] : 'navbar_burger']">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div :class="[is_navbar_menu_opened ? ['navbar_menu', 'is_open'] : 'navbar_menu']">
      <div class="navbar_start" v-if="isLoggedIn">
        <div class="dropdown">
          <a class="dropdown_title" @click="openDropDown1()">首頁</a>
          <div class="dropdown_content"
            :class="[is_dropdown_1_opened ? ['dropdown_content', 'is_open'] : 'dropdown_content']">
            <a href="/favorite_lists" class="navbar_item">最愛清單</a>
            <a href="/strategy_records/TW/" class="navbar_item">台股股票策略紀錄</a>
            <a href="/strategy_records/US/" class="navbar_item">美股股票策略紀錄</a>
          </div>
        </div>
        <div class="dropdown">
          <a class="dropdown_title" @click="openDropDown2()">最愛清單管理</a>
          <div class="dropdown_content"
            :class="[is_dropdown_2_opened ? ['dropdown_content', 'is_open'] : 'dropdown_content']">
            <a href="/favorite_list_management" class="navbar_item">最愛清單管理</a>
          </div>
        </div>
        <div class="dropdown">
          <a class="dropdown_title" @click="openDropDown3()">策略紀錄管理</a>
          <div class="dropdown_content"
            :class="[is_dropdown_3_opened ? ['dropdown_content', 'is_open'] : 'dropdown_content']">
            <a href="/strategy_record_management" class="navbar_item">策略紀錄管理</a>
          </div>
        </div>
        <div class="dropdown">
          <a class="dropdown_title" @click="openDropDown4()">股票管理</a>
          <div class="dropdown_content"
            :class="[is_dropdown_4_opened ? ['dropdown_content', 'is_open'] : 'dropdown_content']">
            <a href="/ticker_management" class="navbar_item">股票管理</a>
          </div>
        </div>
        <div class="dropdown">
          <a class="dropdown_title" @click="openDropDown5()">股票研究/競拍</a>
          <div class="dropdown_content"
            :class="[is_dropdown_5_opened ? ['dropdown_content', 'is_open'] : 'dropdown_content']">
            <a href="/auction_bidding_announcement" class="navbar_item">競拍資訊</a>
            <a href="/drawing_announcement" class="navbar_item">抽籤公告</a>
          </div>
        </div>
        <div class="dropdown">
          <a class="dropdown_title" @click="openDropDown6()">系統管理</a>
          <div class="dropdown_content"
            :class="[is_dropdown_6_opened ? ['dropdown_content', 'is_open'] : 'dropdown_content']">
            <a href="/task_starter" class="navbar_item">任務控制</a>
            <a href="/cloudwatch_log_management" class="navbar_item">CloudWatch 日誌管理</a>
          </div>
        </div>
      </div>
      <div class="navbar_end" v-if="isLoggedIn">
        <div class="user_info">
          <div class="username">Hi, {{ getUserName }}</div>
          <div class="buttons">
            <router-link to="/login" class="login_button" v-if="!isLoggedIn">登入</router-link>
            <button to="/" class="logout_button" @click="logout()" v-if="isLoggedIn">登出</button>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style lang="scss">
body {
  margin: 0;
  font-family: sans-serif;
}

button:focus {
  outline: 0;
}

.navbar {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.navbar_brand {
  display: flex;
  align-items: stretch;
  flex-shrink: 0;
}

.brand_link {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
}

.navbar_menu {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.navbar_menu.is_open {
  max-height: 600px;
  border-bottom: #e5e5e5 solid 1px;
}

.navbar_item {
  display: flex;
  align-items: center;
  color: #636363;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  transition: color 0.2s ease;
}

a.navbar_item:hover {
  color: #5cba80;
}

.navbar_burger {
  color: hsl(0deg, 0%, 29%);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: 3.25rem;
  position: relative;
  width: 3.25rem;
  margin-left: auto;
}

.navbar_burger span {
  background-color: rgb(74, 74, 74);
  display: block;
  height: 1px;
  left: calc(50% - 8px);
  position: absolute;
  transform-origin: center;
  transition-duration: 0.2s;
  transition-property: background-color, opacity, transform;
  transition-timing-function: ease-out;
  width: 16px;
}

.navbar_burger span:nth-child(1) {
  top: calc(50% - 6px);
}

.navbar_burger span:nth-child(2) {
  top: calc(50% - 1px);
}

.navbar_burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.navbar_burger.is_active span:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}

.navbar_burger.is_active span:nth-child(2) {
  opacity: 0;
}

.navbar_burger.is_active span:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}

.dropdown {
  display: flex;
  flex-direction: column;
}

.dropdown_title {
  display: flex;
  align-items: center;
  color: #214938;
  font-size: 1.1rem;
  font-weight: 400;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  cursor: pointer;
}

.dropdown_content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.dropdown_content.is_open {
  max-height: 600px;
}

.logo {
  width: 60px;
  height: 36px;
  margin-right: 0.8rem;
}

.user_info {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
}

.username {
  flex-grow: 1;
  margin: 0.6rem;
}

.buttons {
  flex-grow: 0;
}

.company_name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4e8570;
}

.login_button {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 1rem;
  white-space: nowrap;
  text-decoration: none;
}

.logout_button {
  background-color: #f14668;
  border-width: 1px;
  border-color: transparent;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 1rem;
  white-space: nowrap;
  text-decoration: none;
}

.breadcrumb_block {
  padding: 1rem 1rem;
  margin-bottom: 1rem;
}

ul.breadcrumb {
  list-style: none;
  line-height: 2rem;
  text-align: left;
  padding-inline-start: 0;
}

ul.breadcrumb li {
  color: #1E606F;
  display: inline;
  font-size: 1.2rem;
}

ul.breadcrumb li+li:before {
  padding: 0.5rem;
  color: #1E606F;
  content: "/\00a0";
}

ul.breadcrumb li a {
  color: #248F77;
  display: inline;
  text-decoration: none;
}

// custom input style

input[type=text],
input[type=tel],
input[type=password],
input[type=number],
input[type=email] {
  width: 100%;
  padding: 1rem;
  margin-bottom: 2.5rem;
  box-sizing: border-box;
  font-size: 1rem;
}

input:focus {
  outline: none;
}

input[type=submit] {
  width: 100%;
  font-size: 1rem;
  background-color: #58A469;
  border: none;
  color: white;
  padding: 1rem 2rem;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 2rem;
}

input[type=submit]:disabled {
  background-color: #bdc3c7;
}

input[type=submit][v-cloak] {
  display: inline-block;
  background-color: #bdc3c7;
}

input[type=file] {
  margin-bottom: 2.5rem;
}

input[type=file]::file-selector-button {
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 2px;
  background-color: #58A469;
}

input[type=file]::file-selector-button:hover {
  background-color: #248F77;
}

label {
  display: block;
  color: #1E606F;
  font-size: 1rem;
  margin-bottom: 1rem;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #fff;
  background-image: none;
  font-size: 1rem;
}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 100%;
  height: 2rem;
  line-height: 2;
  background: #fff;
  overflow: hidden;
  border: 1px solid #767676;
  border-radius: 2px;
  margin-bottom: 2.5rem;
  font-size: 1rem;
}

select {
  flex: 1;
  padding: 0.2rem 1rem;
  color: #000;
  cursor: pointer;
}

/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.6rem;
  color: #fff;
  background: #58A469;
  cursor: pointer;
  pointer-events: none;
}

.radio_block {
  margin-bottom: 2.5rem;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]+label {
  display: flex;
  align-items: center;
  height: 2rem;
  margin-bottom: 0;
}

input[type="radio"]+label>span.checked {
  display: none;
}

input[type="radio"]+label>span.unchecked {
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  user-select: none;
}

input[type="radio"]:checked+label>span.checked {
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  user-select: none;
}

input[type="radio"]:checked+label>span.unchecked {
  display: none;
}

.checkbox_block {
  margin-bottom: 2.5rem;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]+label {
  display: flex;
  align-items: center;
  height: 2rem;
  margin-bottom: 0;
}

input[type="checkbox"]:not(:checked)+label>span.checked {
  display: none;
}

input[type="checkbox"]:not(:checked)+label>span.unchecked {
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  user-select: none;
}

input[type="checkbox"]:checked+label>span.checked {
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  user-select: none;
}

input[type="checkbox"]:checked+label>span.unchecked {
  display: none;
}

.date_picker {
  margin-bottom: 1.5rem;
}

input.dp__input {
  border-color: #767676;
}

.grow_wrap {
  margin-bottom: 1rem;
}

textarea {
  width: 97%;
  padding: 0.5rem;
  font: inherit;
}

@media only screen and (min-width: 768px) {

  .navbar {
    display: flex;
    flex-direction: row;
  }

  .navbar_burger {
    display: none;
  }

  .navbar_menu {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-height: 900px;
    overflow: visible;
  }

  .navbar_start {
    display: flex;
    flex-grow: 0;
  }

  .navbar_end {
    display: flex;
    flex-grow: 1;
    flex-direction: row-reverse;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown_title {
    padding: 0.75rem 1rem;
  }

  .dropdown_content {
    display: block;
    position: absolute;
    top: 3rem;
    left: 0;
    background-color: #f9f9f9;
    z-index: 2;
  }

  .dropdown_content.is_open {
    display: block;
    overflow: visible;
  }

  .navbar_item {
    padding: 0.75rem 1rem;
    min-width: 10rem;
  }

  .breadcrumb_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0rem;
  }
}
</style>

<script>
import createStore from './store/index.js'
import axios from 'axios'

document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Add a click event on each of them
  $navbarBurgers.forEach(el => {
    el.addEventListener('click', () => {

      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');

    });
  });

});


export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      is_navbar_menu_opened: false,
      is_dropdown_1_opened: false,
      is_dropdown_2_opened: false,
      is_dropdown_3_opened: false,
      is_dropdown_4_opened: false,
      is_dropdown_5_opened: false,
      is_dropdown_6_opened: false
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')

    const token = this.$store.state.token

    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }
  },
  mounted() {
    document.title = 'kizax stock | 首頁'
  },
  computed: {
    isLoggedIn() {
      return createStore.getters.isLoggedIn
    },
    getUserName() {
      return createStore.getters.getUserName
    }
  },
  methods: {
    openNavbarMenu() {
      this.is_navbar_menu_opened = !this.is_navbar_menu_opened
    },
    openDropDown1() {
      this.is_dropdown_1_opened = !this.is_dropdown_1_opened
    },
    openDropDown2() {
      this.is_dropdown_2_opened = !this.is_dropdown_2_opened
    },
    openDropDown3() {
      this.is_dropdown_3_opened = !this.is_dropdown_3_opened
    },
    openDropDown4() {
      this.is_dropdown_4_opened = !this.is_dropdown_4_opened
    },
    openDropDown5() {
      this.is_dropdown_5_opened = !this.is_dropdown_5_opened
    },
    openDropDown6() {
      this.is_dropdown_6_opened = !this.is_dropdown_6_opened
    },
    async logout() {
      await axios
        .post('/api/v1/login_and_logout/logout/')
        .then(() => {
        })
        .catch((error) => JSON.stringify(error))

      axios.defaults.headers.common["Authorization"] = ""

      localStorage.removeItem("token")
      localStorage.removeItem("username")

      this.$store.commit('setUser', { 'username': '' })
      this.$store.commit('removeToken')
      this.$router.push('/login')
    }
  }
}
</script>

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import createStore from '../store/index.js'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login/',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/ticker_management/',
        name: 'ticker_management',
        component: () => import('../views/TickerManagementView.vue')
    },
    {
        path: '/favorite_list_management/',
        name: 'favorite_list_management',
        component: () => import('../views/FavoriteListManagementView.vue')
    },
    {
        path: '/add_favorite_list/',
        name: 'add_favorite_list',
        component: () => import('../views/AddFavoriteListView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_favorite_list/:favorite_list_id/',
        name: 'edit_favorite_list',
        component: () => import('../views/EditFavoriteListView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/favorite_list_item_management/:favorite_list_id/',
        name: 'favorite_list_item_management',
        component: () => import('../views/FavoriteListItemManagementView.vue')
    },
    {
        path: '/favorite_list_item_strategy_management/:favorite_list_item_id/',
        name: 'favorite_list_item_strategy_management',
        component: () => import('../views/FavoriteListItemStrategyManagementView.vue')
    },
    {
        path: '/favorite_list_strategy_management/:favorite_list_id/',
        name: 'favorite_list_strategy_management',
        component: () => import('../views/FavoriteListStrategyManagementView.vue')
    },
    {
        path: '/strategy_record_management/',
        name: 'strategy_record_management',
        component: () => import('../views/StrategyRecordManagementView.vue')
    },
    {
        path: '/favorite_lists/',
        name: 'favorite_lists',
        component: () => import('../views/FavoriteListsView.vue')
    },
    {
        path: '/tw_favorite_list/:favorite_list_id/',
        name: 'tw_favorite_list',
        component: () => import('../views/TwFavoriteListView.vue')
    },
    {
        path: '/us_favorite_list/:favorite_list_id/',
        name: 'us_favorite_list',
        component: () => import('../views/UsFavoriteListView.vue')
    },
    {
        path: '/strategy_records/TW/',
        name: 'tw_strategy_records',
        component: () => import('../views/TwStrategyRecordsView.vue')
    },
    {
        path: '/strategy_records/US/',
        name: 'us_strategy_records',
        component: () => import('../views/UsStrategyRecordsView.vue')
    },
    {
        path: '/auction_bidding_announcement/',
        name: 'auction_bidding_announcement',
        component: () => import('../views/AuctionBiddingAnnouncementManagementView.vue')
    },
    {
        path: '/task_starter/',
        name: 'task_starter',
        component: () => import('../views/TaskStarterView.vue')
    },
    {
        path: '/drawing_announcement/',
        name: 'drawing_announcement',
        component: () => import('../views/DrawingAnnouncementManagementView.vue')
    },
    {
        path: '/cloudwatch_log_management/',
        name: 'cloudwatch_log_management',
        component: () => import('../views/CloudWatchLogManagementView.vue')
    },
    {
        path: '/404/',
        name: '404',
        component: () => import('../views/Page404View'),
        hidden: true
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404/',
        hidden: true
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!createStore.getters.isLoggedIn) {
            next({ name: 'login' })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

export default router
